/* Global styles */
body {
  font-family: Arial, sans-serif;
}

/* Header styles */
header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

/* Container styles */
.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Input group styles */
.input-group {
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  margin-bottom: 0.5rem;
}

/* Shopping list styles */
.shopping-list {
  margin-top: 2rem;
}

/* Table styles */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.table th,
.table td {
  padding: 0.5rem;
  border: 1px solid #ccc;
  text-align: left;
}

/* Actions button styles */
.actions-button {
  display: flex;
  align-items: center;
}

/* Responsive styles for mobile view */
@media (max-width: 768px) {
  /* Adjust container width for smaller screens */
  .container {
    padding: 0.5rem;
  }

  /* Make table responsive */
  .table {
    overflow-x: auto;
  }

  /* Hide some columns on smaller screens */
  .table th:nth-child(4),
  .table td:nth-child(4) {
    display: none;
  }

  /* Adjust font size for smaller screens */
  .table th,
  .table td {
    font-size: 0.9rem;
  }

  /* Adjust padding for input fields */
  .input-group input {
    margin-bottom: 0.3rem;
  }

  /* Adjust button size for smaller screens */
  .actions-button button {
    font-size: 0.75rem;
    padding: 0.2rem 0.4rem;
  }

  /* Decrease the size of the buttons */
  .btn-action {
    font-size: 0.9rem; /* Adjust the font size as needed */
    padding: 0.3rem 0.6rem; /* Adjust the padding as needed */
  }
}

/* Styles for suggested items */

/* Container for suggested items */
.suggested-items-container {
  overflow-x: auto; /* Enable horizontal scrollbar when content overflows */
  white-space: nowrap; /* Prevent suggested items from wrapping */
}

/* Styles for each suggested item */
.suggested-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

/* Button styles within suggested items */
.suggested-item button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Highlight style for bought items */
.bought {
  background-color: rgba(0, 255, 0, 0.3);
}

/* Override table background color for bought items */
:root .bought {
  --bs-table-bg: rgba(0, 255, 0, 0.3); /* or any other color you prefer */
}

/* Styles for table cells */

/* Quantity cell */
.table td:nth-child(2) {
  /* Make quantity cell smaller */
  width: 5%; /* Adjust the width as needed */
  max-width: 100px; /* Limit the maximum width */
}

/* Actions cell */
.table td:nth-child(5) {
  /* Make actions cell smaller */
  width: 15%; /* Adjust the width as needed */
}

/* Notes cell */
.table td:nth-child(3) {
  /* Make notes cell bigger */
  width: 40%; /* Adjust the width as needed */
}

